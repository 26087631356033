import React, { Fragment } from 'react';
import OPE from 'components/common/EpiOnPageEdit';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';
import { FlexContainerCentered } from 'components/flex';
import { PageContent } from '../../pages/components/';
import { sizes } from 'style/media';
import { Image } from 'components/images/Image';

import {
    CustomLink,
    LinkButton,
    LinkButtonText,
    Row,
    Column,
    PageContentBlockOnlyText
} from './components/TextBlockContainer';

function TextBlockButton({ buttonText, formLink, linkTarget, buttonLink, buttonLinkRef, onClickScroll }) {
    return (
        <>
            {buttonText && formLink ? (
                <LinkButton
                    onClick={() => {
                        onClickScroll();
                    }}>
                    <LinkButtonText>{buttonText}</LinkButtonText>
                </LinkButton>
            ) : (
                buttonText &&
                buttonLink &&
                (linkTarget ? (
                    <CustomLink href={buttonLinkRef} target={'_blank'}>
                        <LinkButton>
                            <LinkButtonText>{buttonText}</LinkButtonText>
                        </LinkButton>
                    </CustomLink>
                ) : (
                    <CustomLink href={buttonLinkRef}>
                        <LinkButton>
                            <LinkButtonText>{buttonText}</LinkButtonText>
                        </LinkButton>
                    </CustomLink>
                ))
            )}
        </>
    );
}

export default class TextBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imgHeight: 0
        };
    }

    onClickScroll = () => {
        let scrollToElement = document.getElementById('formLink');
        if (scrollToElement == null) return null;
        const windowOffset = window.scrollY || window.pageYOffset;

        const { top } = scrollToElement.getBoundingClientRect();
        const scrollY = top;
        window.scroll({
            top: windowOffset + scrollY,
            behavior: 'smooth'
        });
    };

    render() {
        const {
            mainBody,
            media,
            buttonText,
            buttonLink,
            leftCheck,
            buttonLinkRef,
            linkTarget,
            formLink
        } = this.props;

        if (window.innerWidth >= sizes.desktop) {
            return (
                <div>
                    {!media ? (
                        <Fragment>
                            <FlexContainerCentered alignItems="flex-start" marginbottom="1.667rem">
                                <PageContent
                                    className="PageContentClass"
                                    hasrelated={false}
                                    style={{ paddingTop: '1rem' }}>
                                    <PageContentBlockOnlyText>
                                        <OPE propertyName="mainBody" floating={false}>
                                            <HtmlComponentContainer
                                                smallmargin="0rem 0rem 1rem 0rem"
                                                margin="0rem 0rem 1rem 0rem"
                                                maxwidth="100%">
                                                <HtmlComponent html={mainBody} />
                                            </HtmlComponentContainer>
                                        </OPE>
                                        <OPE propertyName="button">
                                            <TextBlockButton buttonText={buttonText} formLink={formLink} linkTarget={linkTarget} buttonLink={buttonLink} buttonLinkRef={buttonLinkRef} onClickScroll={this.onClickScroll} /> 
                                        </OPE>
                                    </PageContentBlockOnlyText>
                                </PageContent>
                            </FlexContainerCentered>
                        </Fragment>
                    ) : leftCheck ? (
                        <PageContent bottommargin>
                            <Row>
                                <Column>
                                    <Image loaded={true} fadein={true} src={media} />
                                </Column>
                                <Column left style={{ paddingLeft: '2rem' }}>
                                    <OPE propertyName="mainBody" floating={false}>
                                        <HtmlComponentContainer
                                            smallmargin="0rem 0rem 1rem 0rem"
                                            margin="0rem 0rem 1rem 0rem">
                                            <HtmlComponent html={mainBody} />
                                        </HtmlComponentContainer>
                                    </OPE>
                                    <OPE propertyName="button">
                                            <TextBlockButton buttonText={buttonText} formLink={formLink} linkTarget={linkTarget} buttonLink={buttonLink} buttonLinkRef={buttonLinkRef} onClickScroll={this.onClickScroll} /> 
                                    </OPE>
                                </Column>
                            </Row>
                        </PageContent>
                    ) : (
                        <PageContent bottommargin>
                            <Row>
                                <Column left style={{ paddingRight: '2rem' }}>
                                    <OPE propertyName="mainBody" floating={false}>
                                        <HtmlComponentContainer
                                            smallmargin="0rem 0rem 1rem 0rem"
                                            margin="0rem 0rem 1rem 0rem">
                                            <HtmlComponent html={mainBody} />
                                        </HtmlComponentContainer>
                                    </OPE>
                                            <OPE propertyName="button">
                                            <TextBlockButton buttonText={buttonText} formLink={formLink} linkTarget={linkTarget} buttonLink={buttonLink} buttonLinkRef={buttonLinkRef} onClickScroll={this.onClickScroll} /> 
                                    </OPE>
                                </Column>
                                <Column>
                                    <Image loaded={true} fadein={true} src={media} />
                                </Column>
                            </Row>
                        </PageContent>
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    <Fragment>
                        <FlexContainerCentered alignItems="flex-start" marginbottom="1em">
                            <PageContent
                                className="PageContentClass"
                                hasrelated={false}
                                style={{ paddingTop: '1rem' }}>
                                <OPE propertyName="mainBody" floating={false}>
                                    <HtmlComponentContainer
                                        smallmargin="0rem 0rem 1rem 0rem"
                                        margin="0rem 0rem 1rem 0rem"
                                        maxwidth="100%">
                                        <HtmlComponent html={mainBody} />
                                    </HtmlComponentContainer>
                                </OPE>
                                <OPE propertyName="button">
                                    {buttonText &&
                                        buttonLink &&
                                        (linkTarget ? (
                                            <CustomLink href={buttonLinkRef} target={'_blank'}>
                                                <LinkButton>
                                                    <LinkButtonText>{buttonText}</LinkButtonText>
                                                </LinkButton>
                                            </CustomLink>
                                        ) : (
                                            <CustomLink href={buttonLinkRef}>
                                                <LinkButton>
                                                    <LinkButtonText>{buttonText}</LinkButtonText>
                                                </LinkButton>
                                            </CustomLink>
                                        ))}
                                </OPE>
                                {media ? <Image loaded={true} fadein={true} src={media} /> : ''}
                            </PageContent>
                        </FlexContainerCentered>
                    </Fragment>
                </div>
            );
        }
    }
}
