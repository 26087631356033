import Cookies from 'js-cookie';
import store from 'store2';
import { COOKIEBAR_COOKIE } from '../features/CookieBar/cookiebarTypes';

class CookieHandler {
  constructor(force) {
    this.force = force || false;
    }
  shouldSaveCookies = () => this.getJSON(COOKIEBAR_COOKIE) ? this.getJSON(COOKIEBAR_COOKIE).accepted : false;
  getJSON = key => Cookies.getJSON(key);
  get = key => Cookies.get(key);
    remove = key => Cookies.remove(key);
  set = (key, value, expires = 30, path = '/') => {
    if (!this.shouldSaveCookies() && !this.force) {
      return;
    }
    return Cookies.set(key, value, { expires: expires, path: path });
  };
  clearAll = () => {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName)
    });
  }
}

class LocalStorage {
  constructor(force) {
    this.force = force;
    this.pre = 'FAST_';
    this.versionKey = `${this.pre}VERSION`;
  }
  remove = key => store.remove(key);
  getJSON = key => store.get(`${this.pre}${key}`);
  set = (key, value) => {
    store.set(`${this.pre}${key}`, value);
  };
  setVersion = version => {
    const last = this.getJSON(this.versionKey);
    if (last === version) return null;
    store.clearAll();
    this.set(this.versionKey, version);
  };
}

export { LocalStorage };
export default CookieHandler;
