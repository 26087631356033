import React from 'react';
import PropTypes from 'prop-types';
import {
    RelatedBlockContainer,
    RelatedBlockWrapper,
    RelatedBlockHeader,
    RelatedBlockText,
    RelatedBlockImage,
    RelatedBlockCategoryContainer,
    SingleRelatedBlockContainer,
    SingleRelatedBlockWrapper,
    SingleRelatedBlockContentWrapper,
    SingleRelatedBlockImage,
    SingleRelatedBlockCategoryContainer,
    RelatedBlockButton,
    RelatedBlockButtonText,
    ExternalIcon
} from './components';
import { SecondaryButtonLink, ButtonText } from 'components/button';
import { colors as Colors } from 'constants/colors';
import CategoryTag from 'features/CategoryTag';
import { relatedMaterialImage, relatedMaterialMultipleImage } from 'utils';

const trimIngress = (ingress, header) => {
    var maxLength = header.length > 50 ? 140 : header.length < 20 ? 200 : 170;
    var trimmedString = '';
    if (ingress.length > maxLength) {
        if (ingress.length > trimmedString.length) {
            trimmedString = ingress.substr(0, maxLength);
            trimmedString = trimmedString.substr(
                0,
                Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            );
            trimmedString = trimmedString + '...';
        }
    }
    return trimmedString;
};

const trimTo110Characters = function (string) {
    if (string.length <= 110) {
        return string;
    } else {
        return string.slice(0, 109) + '…';
    }
};

const RelatedBlock = ({
    header,
    link = '/',
    ingress,
    image,
    categories = [],
    single = false,
    openin,
    external
}) => {
    var loadedIngress = ingress != null ? ingress : '';
    var loadedHeader = header != null ? header : '';
    var trimmedIngress = trimIngress(loadedIngress, loadedHeader);
    const finalIngress = trimTo110Characters(loadedIngress);

    return single ? (
        <SingleRelatedBlockContainer>
            <SingleRelatedBlockImage image={relatedMaterialImage(image)} />
            <SingleRelatedBlockWrapper>
                <SingleRelatedBlockContentWrapper>
                    <SingleRelatedBlockCategoryContainer>
                        {!!categories && (categories.map((i, index) => {
                            if (index >= 2) return null;
                            return <CategoryTag key={index} category={i} />;
                        }))
                        }
                    </SingleRelatedBlockCategoryContainer>
                    {!!header && (
                        <RelatedBlockHeader>{header}</RelatedBlockHeader>
                    )}
                    {!!ingress && (
                        <RelatedBlockText>{trimmedIngress !== '' ? trimmedIngress : ingress}</RelatedBlockText>
                    ) }
                    {!external ? (
                        <SecondaryButtonLink
                            backgroundcolor="transparent"
                            bottom="6%"
                            position="absolute"
                            left="3%"
                            mediumleft="2%"
                            bordercolor={Colors.ORANGE}
                            borderradius="5px"
                            to={link}
                            target={openin}>
                            <ButtonText
                                color={Colors.TOTALLY_BLACK}
                                weight="bold"
                                forcedFontSize={[12, 12]}
                                forcedLineHeight="2.5em">
                                {'Visa mer'}
                            </ButtonText>
                        </SecondaryButtonLink>
                    ) : (
                        <RelatedBlockButton
                            backgroundcolor="transparent"
                            bottom="6%"
                            position="absolute"
                            left="3%"
                            mediumleft="2%"
                            bordercolor={Colors.ORANGE}
                            borderradius="5px"
                            href={link}
                            target={'_blank'}>
                            <RelatedBlockButtonText
                                color={Colors.TOTALLY_BLACK}
                                weight="bold"
                                forcedFontSize={[12, 12]}
                                forcedLineHeight="2.5em">
                                {'Visa mer'}
                                <ExternalIcon />
                            </RelatedBlockButtonText>
                        </RelatedBlockButton>
                    )}
                </SingleRelatedBlockContentWrapper>
            </SingleRelatedBlockWrapper>
        </SingleRelatedBlockContainer>
    ) : (
        <RelatedBlockContainer>
            <RelatedBlockImage image={relatedMaterialMultipleImage(image)} />
            <RelatedBlockWrapper>
                <RelatedBlockCategoryContainer>
                    {!!categories && (categories.map((i, index) => {
                        if (index >= 2) return null;
                        return <CategoryTag key={index} category={i} />;
                    }))}
                    </RelatedBlockCategoryContainer>
                    {!!header && (
                          <RelatedBlockHeader>{header}</RelatedBlockHeader>
                    )}
                    {!!finalIngress && (
                        <RelatedBlockText>{finalIngress}</RelatedBlockText>
                    ) }
                {!external ? (
                    <SecondaryButtonLink
                        backgroundcolor="transparent"
                        bottom="6%"
                        position="absolute"
                        left="3%"
                        mediumleft="2%"
                        bordercolor={Colors.ORANGE}
                        borderradius="5px"
                        to={link}
                        target={openin}>
                        <ButtonText
                            color={Colors.TOTALLY_BLACK}
                            weight="bold"
                            forcedFontSize={[12, 12]}
                            forcedLineHeight="2.5em">
                            {'Visa mer'}
                        </ButtonText>
                    </SecondaryButtonLink>
                ) :  (
                    <RelatedBlockButton
                        backgroundcolor="transparent"
                        bottom="6%"
                        position="absolute"
                        left="3%"
                        mediumleft="2%"
                        bordercolor={Colors.ORANGE}
                        borderradius="5px"
                        href={link}
                        target={'_blank'}>
                        <RelatedBlockButtonText
                            color={Colors.TOTALLY_BLACK}
                            weight="bold"
                            forcedFontSize={[12, 12]}
                            forcedLineHeight="2.5em">
                            {'Visa mer'}
                            <ExternalIcon />
                        </RelatedBlockButtonText>
                    </RelatedBlockButton>
                )}
            </RelatedBlockWrapper>
        </RelatedBlockContainer>
    );
};

RelatedBlock.propTypes = {
    header: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    ingress: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    categories: PropTypes.array
};

export { RelatedBlock };
